import includes from 'lodash/includes'

const MAX_EXCEL_SIZE = 52428800 // 50 MB
export const PdfMimeType = 'application/pdf'
export const XlsMimeType = 'application/vnd.ms-excel'
export const XlsxMimeType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const PngMimeType = 'image/png'
export const JpgMimeType = 'image/jpg'
export const JpegMimeType = 'image/jpeg'
export const GifMimeType = 'image/gif'
export const Mp4MimeType = 'video/mp4'

const validReportFileTypes = [XlsMimeType, XlsxMimeType]

export const IMAGE_FILE_TYPE = 'SiteImageData';
export const VIDEO_FILE_TYPE = 'SiteVideoData';

export const isValidReportFileType = (file: File) => {
    return !!includes(validReportFileTypes, file.type)
}

export const isValidReportFileSize = (file: File) => {
    return !!(file.size <= MAX_EXCEL_SIZE)
}

export const base64ToBlob = (base64: string, mimeType: string) => {
    if (base64 && mimeType && base64.length > 0) {
        const binary = atob(base64.replace(/\s/g, '')) // IE can't handle spaces
        const len = binary.length
        const buffer = new ArrayBuffer(len)
        const data = new Uint8Array(buffer)
        for (let i = 0; i < len; i++) {
            data[i] = binary.charCodeAt(i)
        }
        return new Blob([data], { type: mimeType })
    }
    return new Blob()
}

// export const showDocOnNewTab = (fileBase64: string, mimeType: string) => {
//     const fileBlob = base64ToBlob(fileBase64, mimeType)
//     // IE special
//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//         window.navigator.msSaveOrOpenBlob(fileBlob)
//         return
//     }
//
//     // For other browsers, create a tag and trigger new tab click
//     const data = window.URL.createObjectURL(fileBlob)
//     const link = document.createElement('a')
//     link.href = data
//     link.target = '_blank'
//     link.click()
//
//     setTimeout(function() {
//         // For Firefox it is necessary to delay revoking the ObjectURL
//         window.URL.revokeObjectURL(data)
//     }, 100)
// }