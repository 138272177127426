import React, {ReactElement} from "react";
import {ImageCmsFile, ImageFile} from "@apps/Shared/Types/ImageFile";
import {ButtonLinkBlockProps} from "@sharedComponents/ButtonLinkBlock/types";

export enum HeroRatio {
    Ratio25 = 'Ratio25',
    Ratio45 = 'Ratio45',
}

export enum HeroTextPosition {
    Left = 'Left',
    Right = 'Right',
}

export enum HeroColorMode {
    Dark = 'Dark',
    Light = 'Light',
}

export enum ContentImagePosition {
    Left = 'LEFT',
    Right = 'RIGHT',
}

export enum ContentTextAlignment {
    Left = 'LEFT_ALIGN',
    Center = 'CENTER_ALIGN',
    Right = 'RIGHT_ALIGN',
}

export enum HeroWavePosition {
    Bottom = 'bottom',
    Top = 'top',
}

export enum HeroBgDisplayMode {
    Low = 'LOW',
    High = 'HIGH',
}

export enum HeroColorTheme {
    LightBg = 'LIGHT_BG',
    DarkBg = 'DARK_BG',
}

export type HeroProps = {
    children?: ReactElement | ReactElement[] | any[];
    bgImageSrc?: string;
    bgVideoSrc?: string;
    bgVideoType?: string;
    textPosition?: HeroTextPosition;
    style?: string;
    parallax: boolean;
    wave: string;
    bgVideo?: React.ReactNode;
    bgImage?: React.ReactNode;
    noMargin?: boolean;
    colorMode?: HeroColorMode;
    altText?: string;
}

export type HeroState = {
    height: number;
    isMounted: boolean;
    playing: boolean;
}

export type Dimensions = {
    height: number;
    width: number;
}

export type ImageType = {
    width: number;
    height: number;
}

export enum FontStyle {
    Gotham = 'Gotham',
    Pantamera = 'Pantamera',
}

export type HeroBlockProps = {
    __typename: string;
    PreHeading?: string;
    PreHeadingFont?: FontStyle;
    Heading?: string;
    TeaserText?: string;
    TeaserTextFont?: FontStyle;
    ContentImagePosition: ContentImagePosition;
    ContentTextAlignment: ContentTextAlignment;
    RelativePath?: string;
    Background: {
        Expanded?: ImageCmsFile;
    }
    Image: {
        Expanded?: ImageCmsFile;
    }
    PreviewImage: {
        Expanded?: ImageCmsFile;
    }
    Buttons?: [{
        ContentLink: {
            Expanded: ButtonLinkBlockProps;
        }
    }]
    HeroIsHeadingH1?: boolean;
    ColorTheme: string;
    AltText?: string;
    IncludedFeature: string;
}

export interface HeroAppProps {
    id: number;
}
