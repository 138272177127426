import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ExternalVideoProps, ExternalVideoLayout, VideoService} from './types'
import Panel from '../Panel'
import Icon from '../Icon'

function containsVimeoDomain(url: string) {
    return url.indexOf('vimeo.com') !== -1
}

function getVideoService(url = '') {
    if (containsVimeoDomain(url)) {
        return VideoService.Vimeo
    }

    return VideoService.YouTube
}

function getUrlParams(videoService: VideoService) {
    if (videoService === VideoService.Vimeo) {
        return '?autoplay=1&autopause=0'
    } else if (videoService === VideoService.YouTube) {
        return '?autoplay=1&rel=0'
    }

    return ''
}

const ExternalVideo = (props: ExternalVideoProps) => {
    const {t} = useTranslation()

    const videoService = getVideoService(props.url)

    const [state, setState] = useState({
        play: props.autoPlay || false,
    })

    const clickPlay = () => {
        setState({
            ...state,
            play: true,
        })
    }

    const content = (
        <div className='external-video' style={
            props.backgroundImageSrc
                ? {backgroundImage: `url(${props.backgroundImageSrc})`}
                : undefined
        }>
            <div className='external-video__button-container'>
                <div className={`external-video__button ${state.play ? 'external-video__button--hidden' : ''}`}
                     onClick={clickPlay} tabIndex={0}
                     role="button"
                     aria-label={t('button.playvideo')}>
                    <Icon
                        iconName={'play'}
                        size={24}
                        color={'#fffffa'}
                    ></Icon>
                    <Icon
                        iconName={'play'}
                        size={64}
                        color={'#ffffff'}
                    ></Icon>
                </div>
            </div>
            {state.play && (
                <iframe
                    className="cookieconsent-optin-marketing"
                    src={`${props.url + getUrlParams(videoService)}`}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    frameBorder="0"
                ></iframe>
            )}
            {props.title && <div
                className={`external-video__title ${state.play ? 'external-video__title--hidden' : ''}`}>{props.title}</div>}
            <div className="cookieconsent-optout-marketing">
                Please{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: Cookiebot.renew()" className={'cookiebot-renew-link'}>
                    accept marketing cookies
                </a>
                to view this content.
            </div>
        </div>
    )

    return (
        <div className='external-video-wrapper'>
            {props.layout === ExternalVideoLayout.Boxed ? (
                <Panel>{content}</Panel>
            ) : (
                content
            )}
        </div>
    )
}

export default ExternalVideo
