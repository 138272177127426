export enum LinkType {
    Download = 'download',
    Extern = 'extern',
    Intern = 'intern',
    Locked = 'locked',
    Print = 'print',
}

export enum LinkCategory {
    Document = 'document',
    MyPages = 'my-pages',
    News = 'news',
    Page = 'page',
    EService = 'eservice',
}

export enum LinkTheme {
    Default = 'default',
    DarkMode = 'darkmode',
}

export type IProps = {
    size: string
    category: LinkCategory
    linkType: LinkType
    badgeIcon?: string
    title: string
    date?: string
    teaser?: any
    link?: string
    onClick?: Function
    loading?: boolean
    hideIcon?: boolean
    meta?: any
    disabled?: boolean
    colorTheme?: string
}

export type IInlineLinkProps = {
    id?: string
    text: string
    type: LinkType
    url: string
    onClick?: any
    hideIcon?: boolean
    loading?: boolean
    theme?: LinkTheme
}
