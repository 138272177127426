import { LinkType } from './types'

export const iconForLinkType = (linkType: LinkType): string => {
    switch (linkType) {
        case LinkType.Intern:
            return 'arrow-right'
        case LinkType.Extern:
            return 'external-link'
        case LinkType.Locked:
            return 'lock'
        case LinkType.Download:
            return 'download'
        case LinkType.Print:
            return 'print'
        default:
            return 'arrow-right'
    }
}
