import { isBrowser } from "../../Utils"
import BrowserStorage, { StorageType } from "../../Utils/BrowserStorage"
import EnvironmentUrlProvider from "./Environment"
import { EnvObject } from "./Environment/types"
import {ENVIRONMENT_CONFIG} from "@frontendUtils/browser-storage";

const configurer = () => {
    let currentConfig: any
    const localStorage = isBrowser
        ? new BrowserStorage(StorageType.LOCAL)
        : null

    if (!isBrowser) {
        currentConfig = {
            SITE_BASE_URL: '',
            API_BASE_URL: '',
            PORTAL_LOGIN_URL: '',
        }
    }
    if (localStorage) {
        const LS = JSON.parse(localStorage.getItem(ENVIRONMENT_CONFIG) || '{}')
        const envConf: EnvObject = Object.entries(LS).length
            ? LS
            : EnvironmentUrlProvider.GetEnvironmentConfig()

        if (envConf && envConf.urls) {
            currentConfig = {
                SITE_BASE_URL: envConf.urls.site,
                API_BASE_URL: envConf.urls.api,
                PORTAL_LOGIN_URL: envConf.urls.login
            }
        }
    }

    return currentConfig
}

export default configurer()