document.addEventListener('DOMContentLoaded', () => {
    // Track the fullscreen state
    let isFullscreenOpen = false;

    checkHelpElements();

    // Set up a MutationObserver to monitor the class attribute on the body
    const body = document.body;
    const observer = new MutationObserver(
        throttle((mutations: MutationRecord[], observer: MutationObserver) => {
            // Check if fullscreen-open class is added or removed
            const currentlyFullscreenOpen = body.classList.contains('fullscreen-open');

            if (currentlyFullscreenOpen && !isFullscreenOpen) {
                // Fullscreen just opened
                checkHelpElements();
            } else if (!currentlyFullscreenOpen && isFullscreenOpen) {
                // Fullscreen just exited
                resetCookiebotWidget();
            }

            // Update the state
            isFullscreenOpen = currentlyFullscreenOpen;
        }, 50)
    );

    // Observe the body for class attribute changes
    observer.observe(body, { attributes: true });
});

/**
 * Check all elements with the class 'help' to see if they are at the bottom of the screen.
 * If a help element is at the bottom, adjust the style of #CookiebotWidget.
 */
function checkHelpElements(): void {
    const helpElements = document.querySelectorAll<HTMLDivElement>('.help');
    const cookiebotWidget = document.getElementById('CookiebotWidget');

    if (!cookiebotWidget) return; // Exit if CookiebotWidget is not found

    let isAnyHelpAtBottom = false;

    helpElements.forEach((helpElement) => {
        if (isAtBottomOfScreen(helpElement)) {
            isAnyHelpAtBottom = true;
        }
    });

    cookiebotWidget.style.bottom = isAnyHelpAtBottom ? '120px' : '';    
}

/**
 * Reset the bottom position of #CookiebotWidget when fullscreen mode exits.
 */
function resetCookiebotWidget(): void {
    const cookiebotWidget = document.getElementById('CookiebotWidget');

    if (cookiebotWidget) {
        cookiebotWidget.style.bottom = ''; // Reset to default
    }
}

/**
 * Check if an element is at the bottom of the screen.
 * @param element - The element to check.
 * @returns True if the element is at the bottom of the screen, otherwise false.
 */
function isAtBottomOfScreen(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    // Determine if the element's bottom aligns with the viewport's bottom
    return Math.abs(rect.bottom - viewportHeight) <= 1; // Allow a small tolerance for rounding errors
}

/**
 * Throttle function to limit execution frequency.
 * @param func - The function to throttle.
 * @param limit - The time limit in milliseconds.
 * @returns A throttled version of the function.
 */
function throttle<T extends (...args: any[]) => void>(func: T, limit: number): T {
    let inThrottle: boolean;
    return function (this: any, ...args: any[]) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    } as T;
}